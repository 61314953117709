'use client';

import React from 'react';
import { Toaster as Sonner } from 'sonner';

import { getApiErrorMessage } from '@/shared/types';

type ToasterProps = React.ComponentProps<typeof Sonner>;

const Toaster = ({ ...props }: ToasterProps) => {
  return (
    <Sonner
      theme="system"
      visibleToasts={1}
      position="top-center"
      className="flex flex-col items-center justify-center"
      toastOptions={{
        duration: 3000,
        unstyled: true,
        classNames: {
          // Elements
          toast:
            'px-5 py-3 rounded-unit-12 flex items-center gap-2 bg-neutral-s13 dark:bg-neutral-s13 text-content-inverse dark:text-content-inverse',
          // Colors
          error:
            '!bg-feedback-danger-filled-default !dark:bg-feedback-danger-filled-default',
          success: '!bg-neutral-s13 text-content-inverse',
          warning: 'text-yellow-400',
          info: '!bg-feedback-information-filled-default !dark:bg-feedback-information-filled-default',
        },
      }}
      {...props}
    />
  );
};

const toastIconSize = 24;

const loadingToastOptions = {
  loading: 'Loading...',
  error: (error: any) => getApiErrorMessage(error),
};

export { Toaster, toastIconSize, loadingToastOptions };
