import { AxiosError } from 'axios';

import { SSOServiceProviderName } from '@/lib/sso-service/types';
import { logError } from '@/lib/utils';

export type StringBoolean = 'true' | 'false';

// =============================================================================
// API
// =============================================================================
export const enum HttpMethod {
  Get = 'GET',
  Put = 'PUT',
  Post = 'POST',
  Patch = 'PATCH',
  Delete = 'DELETE',
}

type BaseApiConfig = {
  method: HttpMethod;
};

interface BaseGetApiConfig extends BaseApiConfig {
  queryKey: any[];
}

export interface GetApiConfig extends BaseGetApiConfig {
  url: string;
}

export const isGetApiConfig = (config: any): config is GetApiConfig =>
  Boolean(config.queryKey);

export interface DynamicGetApiConfig extends BaseGetApiConfig {
  url: (params?: any) => string;
}

export const isDynamicGetApiConfig = (
  config: any,
): config is DynamicGetApiConfig => typeof config.url === 'function';

export interface RestApiConfig extends BaseApiConfig {
  url: string;
}

export const isRestApiConfig = (config: any): config is RestApiConfig =>
  typeof config.url === 'string';

export interface DynamicRestApiConfig extends BaseApiConfig {
  url: (params?: any) => string;
}

export const isDynamicRestApiConfig = (
  config: any,
): config is DynamicRestApiConfig => typeof config.url === 'function';

// Base API
export const enum ApiErrorCode {
  InvalidRequest = 'INVALID_REQUEST',
  ResourceNotFound = 'RESOURCE_NOT_FOUND',
}

export interface BaseApiError {
  message: string;
  code: ApiErrorCode;
  fieldErrors?: BaseApiError[];
}

export type ApiError = AxiosError<BaseApiError>;

export function isApiError(error: any): error is ApiError {
  return Boolean(error?.response?.data?.code);
}

export function getApiErrorMessage(error: any): string {
  if (!error) {
    return 'Something went wrong';
  }

  if (isBeneficiaryApiError(error)) {
    return getBeneficiaryApiErrorMessage(error);
  }

  // If is some other error
  if (!isApiError(error)) {
    // Return the message
    return (error as AxiosError).message;
  }

  // Get the field errors
  const fieldErrors = error.response?.data.fieldErrors;

  // If fields have errors
  if (fieldErrors && fieldErrors.length > 0) {
    const fieldErrorMessage = fieldErrors[0].message;

    if (fieldErrorMessage) {
      return fieldErrorMessage;
    }
  }

  // Otherwise return the message
  return error.response?.data.message!;
}

// Beneficiary API
export enum BeneficiaryApiErrorCode {
  BeneficiaryNotFound = '400000',
  BeneficiaryAlreadyExists = '400000',
}

export interface BaseBeneficiaryApiError {
  code: BeneficiaryApiErrorCode;
  description: string;
  responseCode: string;
  responseMessage: string;
  success: boolean;
}

export type BeneficiaryApiError = AxiosError<BaseBeneficiaryApiError>;

export function isBeneficiaryApiError(
  error: any,
): error is BeneficiaryApiError {
  return Boolean(
    error?.response?.data?.code && error?.response?.data?.description,
  );
}

export function getBeneficiaryApiErrorMessage(error: any): string {
  if (!error) {
    return 'Something went wrong';
  }

  // If is some other error
  if (!isBeneficiaryApiError(error)) {
    // Return the message
    return (error as AxiosError).message;
  }

  const message = error?.response?.data?.description;

  // If message is JSON
  if (message?.includes('"description"')) {
    try {
      return JSON.parse(message).description;
    } catch (error) {
      logError(error);
      return 'Something went wrong';
    }
  }

  return message ?? 'Something went wrong';
}

// =============================================================================
// Auth
// =============================================================================
export interface AuthCredentials {
  phone: string;
  password: string;
}

export interface SignUpData extends AuthCredentials {
  flow: 'phone' | 'sso';
  /** Whether to allow navigation away from the signup page */
  disableNavigation?: boolean;
}

export interface AuthData extends AuthCredentials {
  flow: 'login' | 'recover-password';
}

export interface PasswordRecoveryData {
  phone: string;
  otp: string;
  newPassword: string;
  verificationId: string;
  disableNavigation?: boolean;
}

export interface SigninWithPhonePayload {
  username: string;
}

export interface SignInWithPhoneResponse {}

export type DeviceType = 'WEB' | 'ANDROID' | 'IOS';
export type DeviceStatus = 'ACTIVE' | 'INACTIVE';
export type TwoFactorAuthStep = 'OTP' | 'FACE_MATCH' | 'PASSCODE';
export type TwoFactorAuthStepStatus = 'CREATED' | 'PENDING' | 'COMPLETED';
export type TwoFactorAuthStatus = 'PENDING' | 'COMPLETED' | 'EXPIRED';

export interface DeviceInfoPayload {
  deviceId: string;
  type: DeviceType;
  manufacturer: string;
  model: string;
}

export interface DeviceInfoCookie extends DeviceInfoPayload {
  isKnown: boolean;
}

export interface DeviceInfoResponse extends DeviceInfoPayload {
  username: string;
  isKnown: boolean;
  status: DeviceStatus;
}

export interface LoginUserPayload {
  passcode?: string;
  idToken?: string;
  '2faId'?: string;
  username?: string;
  deviceInfo: DeviceInfoPayload;
  socialProvider?: SSOServiceProviderName;
}

export interface TwoFaStep {
  step: TwoFactorAuthStep;
  status: TwoFactorAuthStepStatus;
}

export interface BaseTwoFa {
  id: string;
  deviceInfo: DeviceInfoResponse;
  mfaAction: string;
  username: string;
  requiredSteps: TwoFactorAuthStep[];
  expiryInSeconds: number;
  steps: TwoFaStep[];
  status: TwoFactorAuthStatus;
}

export interface InitTwoFaRequestPayload {
  deviceInfo: DeviceInfoPayload;
  mfaAction: string;
  username: string;
  requiredSteps?: TwoFactorAuthStep[];
}

export type LoginUserErrorResponse = AxiosError<{
  code: string;
  message: string;
  mfa: TwoFaResponse;
}>;

export interface LoginUserSuccessResponse {
  accessToken: string;
}

export interface TwoFactorAuthStepUrlParams {
  id?: string;
  step?: string;
}

export interface Start2faStepResponse {
  sumsubAccessToken?: string;
  faceMatchVerificationId?: string;
}

export interface TwoFaServiceConfig
  extends Partial<BaseTwoFa>,
    Start2faStepResponse {
  twoFaAction?: string;
  to?: string; // otp sent to
}

export interface TwoFaResponse extends BaseTwoFa {}

export interface Complete2faStepPayload {
  token: string;
}

export const mockAccessToken: string =
  'eyJhbGciOiJSUzI1NiIsInR5cCI6IkpXVCJ9.eyJsYXN0TmFtZSI6bnVsbCwicmVzZXRQYXNzd29yZCI6ZmFsc2UsInVzZXJfbmFtZSI6Iis0NDIwNzEyMzY5MTMiLCJhdXRoZW50aWNhdGlvbkRvbWFpbiI6IkNPU01PUyIsInJvbGVzIjpbXSwiYnJhbmNoIjpudWxsLCJwYW5pY01vZGUiOmZhbHNlLCJhdXRob3JpdGllcyI6W10sImNsaWVudF9pZCI6ImZ4LWlkZW50aXR5LXNlcnZpY2UiLCJkZXZpY2VTdGF0dXMiOiJERUZBVUxUIiwiZmlyc3ROYW1lIjpudWxsLCJhdWQiOlsiY29zbW9zLWNvbnRyb2wtc2VydmljZSJdLCJzY29wZSI6WyJwcm9maWxlIl0sIm5hbWUiOiIgIiwidXNlclR5cGUiOiJMb2NhbCBVc2VyIiwiZXhwIjoxNzIwNDY5MDg0LCJqdGkiOiIxNTFmYzE0MC00MGI1LTRkYmItYWRlMC1kNWNkYTk5ZjUyZDIiLCJ1c2VybmFtZSI6Iis0NDIwNzEyMzY5MTMifQ.nGCNLavBfQPxwCcv8D4DUZe2pJbkY9diz4yUiZRwUgJjkAqxRsII9qo2h1KmMEs2_qsR8GiNA_9SoyLNvE0oxWBsqMF4lMiHu0LCd-T3g_1O6AaelxDw8cAch8Qc9T4Gw5hF6SM8T-VjJ6DzX0Uws8GzKCC_cY-zDl1n5LqIb9hKUmLZwvfvxoOHAbP4LNqFyrzjQlm-ShEL5M5m5dVUX2hlMhyiG4yFDkxInXJBoMaWeAu1_l5iB5_4ltV2owzXvzbwA_Gdd9Pty2psEgBbiDavC44-qsZdI_lW2gBqzJoSSC49P7hxVC2CMperMz73wierhF3KWxpFkuHpS-1ADg';

export interface VerifyOtpPayload {
  to: string;
  code: string;
}
export interface VerifyOtpResponse {
  id: string;
  to: string;
  status: string;
}

export interface SigninFlowConfig {
  data: {
    idToken?: string;
    socialProvider?: SSOServiceProviderName;
    username?: string;
    passcode?: string;
    otp?: string;
    isSSO?: boolean;
  };
  phoneNumberForm: {
    title: string;
    subtitle: string;
    ctaText: string;
    ssoEnabled: boolean;
  };
  passcodeVerification: {
    title: string;
    subtitle: string;
    passcodeLength: number;
  };
}

export interface RecoverPasscodeFlowConfig {
  data: {
    idToken?: string;
    socialProvider?: SSOServiceProviderName;
    newPasscode?: string;
    confirmedPasscode?: string;
  };
  initiateRecoverPasscode: {
    title: string;
    subtitle: string;
    ssoEnabled: boolean;
    ctaText: string;
  };
  createNewPasscode: {
    title: string;
    subtitle: string;
    passcodeLength: number;
  };
  confirmNewPasscode: { title: string; passcodeLength: number };
}

// =============================================================================
// Internationalization
// =============================================================================
export type CountryCode = 'GB' | 'NG';
export interface Country {
  name: string;
  code: CountryCode;
  callingCode: string;
  currencyCode: CurrencyCode;
  bankCodeLength: {
    min: number;
    max: number;
  };
  backAccountNumberLength: {
    min: number;
    max: number;
  };
}

export type CurrencyCode = 'GBP' | 'NGN';

export interface Currency {
  name: string;
  symbol: string;
  code: CurrencyCode;
  countryCode: string;
}

export interface BaseConversionRate {
  amount: number;
  sourceCurrencyCode: CurrencyCode;
  destinationCurrencyCode: CurrencyCode;
}

export interface ConversionRate extends BaseConversionRate {
  rate: number;
}

export interface ArticleThumbnailSchema {
  title: string;
  subtitle: string;
  imageSrc: string;
}

export interface ArticleThumbnailSchema {
  title: string;
  subtitle: string;
  imageSrc: string;
}

export interface ArticleThumbnailSchema {
  title: string;
  subtitle: string;
  imageSrc: string;
}

export interface TransactionFilters {
  timePeriod: { startDate?: string; endDate?: string };
  accounts: { accounts?: string[]; showTransfers?: boolean };
  transferCategories: TransferCategory[];
}

export interface TransactionsConfig {
  filters: TransactionFilters;
  transactions: TransactionResponse[];
  showFilters: boolean;
  size: number;
  page: number;
  totalPages: number;
  totalElements: number;
  searchValue: string;
}

export type TransferType =
  | 'REMITTANCE'
  | 'WALLET_DEPOSIT'
  | 'EXCHANGE'
  | 'WALLET_WITHDRAWAL'
  | 'INTRA_BANK';

export type TransferCategory = 'INFLOW' | 'OUTFLOW' | 'EXCHANGE';

export interface FetchTransactionsPayload {
  startDate?: string;
  endDate?: string;
  transferCategory?: TransferCategory;
  searchValue?: string;
  currencyCode?: string;
  accountId?: string;
  showTransfers?: boolean;
  page?: number;
  size?: number;
}

export interface FetchTransactionsResponse {
  data: {
    content: TransactionResponse[];
    pageNumber: number;
    pageSize: number;
    totalElements: number;
    totalPages: number;
  };
}

export interface FetchTransactionDetailsPayload {
  id: string;
}

export type RawDate = string | Date | number;

export interface TransferTransitions {
  message: string;
  timestamp: string;
}

export interface BaseTransaction {
  sourceAmount: number;
  destinationAmount: number;
  sourceCurrencyCode: CurrencyCode;
  destinationCurrencyCode: CurrencyCode;
  transferTimestamp: string;
}

export interface TransactionResponse extends BaseTransaction {
  tag: string;
  direction: TransferDirection;
  destinationAccount: string;
  destinationAccountName: string;
  narration: string;
  sourceAccount: string;
  sourceAccountName: string;
  transferReference: string;
  transferType: TransferType;
}

export interface ManualBankTransferAccountDetails {
  accountName?: string;
  accountNumber?: string;
  accountSortCode?: string;
  bankCode?: string;
  bankName?: string;
  message?: string;
  paymentReference?: string;
}

export type TransferDirection = 'INWARD' | 'OUTWARD' | 'EXCHANGE';

export interface TransactionDetailResponse extends BaseTransaction {
  status: string;
  recipientName: string;
  recipientBank: string;
  recipientAccountNumber: string;
  remittanceRate: number;
  customerRate: number;
  paymentMethodName: string;
  transferTransitions: TransferTransitions[];
  direction: TransferDirection;
  manualBankTransferAccountDetails?: ManualBankTransferAccountDetails;
}

export interface FetchTransactionDetailsResponse {
  status: string;
  data: TransactionDetailResponse;
}

export type TaskType = 'UPGRADE_KYC' | 'VERIFY_EMAIL' | 'VERIFY_PHONE';

export interface TaskResponse {
  type: TaskType;
  name: string;
  description: string;
  webRedirectUrl: string;
  appRedirectUrl: string;
  base64Icon: string;
  navigationParameters: {
    extraParameter: string;
  };
}

export type UpgradeKycStep = 'start' | 'id-verification' | 'status';

export type FetchTasksResponse = TaskResponse[];

export type DashboardMessagingDialogType =
  | 'KYC_UPGRADE_SUCCESS'
  | 'TRANSFER_SUCCESS'
  | 'NEW_USER';

export type DashboardMessagingAlertType =
  | 'EMAIL_VERIFICATION_SUCCESS'
  | 'PHONE_VERIFICATION_SUCCESS';

export interface OtpUiConfig {
  title: string;
  subtitle: string;
  passcodeLength: number;
  showUsername: boolean;
  showDeviceSpecificTitle?: boolean;
}

export interface PasscodeUiConfig {
  title: string;
  subtitle: string;
  passcodeLength: number;
}

export interface TwoFaUiConfig {
  OTP?: OtpUiConfig;
  FACE_MATCH?: {};
  PASSCODE?: PasscodeUiConfig;
}

export interface Create2faSessionProps
  extends Pick<BaseTwoFa, 'username' | 'mfaAction'> {
  redirectFallback: string;
  redirectTo: string;
}
