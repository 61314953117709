'use client';

import { createContext, useContext, useState } from 'react';

import { TwoFaServiceConfig } from '@/shared/types';

export type TwoFaServiceContextValue = {
  twoFaConfig: TwoFaServiceConfig;
  setTwoFaConfig: (uiData: TwoFaServiceConfig) => void;
};

export const TwoFaServiceContext = createContext(
  {} as TwoFaServiceContextValue,
);

export const useTwoFaServiceContext = () => useContext(TwoFaServiceContext);

export const TwoFaServiceContextProvider = ({
  children,
  config,
  setConfig,
}: {
  children: React.ReactNode;
  config?: TwoFaServiceConfig;
  setConfig?: (config: TwoFaServiceConfig) => void;
}) => {
  const [twoFaConfig, setTwoFaConfig] = useState<TwoFaServiceConfig>(
    config || {},
  );

  return (
    <TwoFaServiceContext.Provider
      value={{ twoFaConfig, setTwoFaConfig: setConfig || setTwoFaConfig }}
    >
      {children}
    </TwoFaServiceContext.Provider>
  );
};
